import { setCMSBarWidth } from './cms-bar-width'


document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')
    const openMenu = document.getElementById('openMenu')
    const closeMenu = document.getElementById('closeMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')
    menu(openMenu, 'hide-icon')
    menu(closeMenu, 'show-icon')

    /* clone submenu */
    const activeSubmenu = document.querySelector('.active-submenu')
    if (activeSubmenu) {
        const clone = activeSubmenu.cloneNode(true)
        document.getElementById('clon-submenu').appendChild(clone)
    }

    /* add class active-submenu */
    const menuWrapper = document.getElementsByClassName('menu-wrapper')
    if (menuWrapper.length > 0) {
        document.getElementsByClassName('container')[0].classList.add("active-submenu")
    }

    /* aldyryn form add actually time zone and date */
    const timeZoneClass = document.querySelector(".time-zone")
    const actuallyTimeClass = document.querySelector('.actually-time')
    if (timeZoneClass && actuallyTimeClass) {
        timeZoneClass.value = Intl.DateTimeFormat().resolvedOptions().timeZone
        const today = new Date()
        actuallyTimeClass.value = `${today.toLocaleDateString()} ${today.toLocaleTimeString()}`
    }

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})
